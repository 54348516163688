<template>
  <div class="freeze-concept">
    <Nav paths="/freeze" />
    <div class="banner">
      <div class="banner-bg"></div>
    </div>
    <div class="principle">
      <div class="en-title">Principle Of food Preservation</div>
      <div class="chs-title">食品保鲜的原理</div>
      <div class="p-text">
        肉、鱼、蔬菜等食物都含有水分。而普通冷冻、冻眠冷冻对水分结晶的效果是完全不同的，就造成了二者保鲜效果的天壤之别了：普通冷冻下，冷冻速度缓慢，经过冰晶膨胀温度带(最大冰晶生成带:0℃~-5℃)时，水分形成冰晶。相比食材的细胞约为20微米，形成的冰晶直径更大。破坏细胞膜和细胞壁，解冻时包含食材的营养，美味成分的物质(血水)流出。东晖冷东，能极速通过最大冰晶生成带，形成的冰晶控制在5微米左右，冰晶极其微细，不会破坏细胞，解冻后也能恢复冷冻前食品状态。
      </div>
      <div class="principle-img" data-aos="zoom-in" data-aos-duration="1000">
        <img :src="require('@/assets/freeze/bg2-1.png')" width="1022" height="568">
      </div>
    </div>
    <div class="historys">
      <div class="history-row">
        <div class="history-chs-title" data-aos="fade-up" data-aos-duration="1000">食品冷冻技术发展史</div>
        <div class="history-en-title" data-aos="fade-up" data-aos-duration="1000">Development history of food freezing technology</div>
        <div class="history-item" v-for="(item,i) in history_list" :key="i" data-aos="zoom-in" data-aos-duration="1000">
          <img :src="item.image" class="history-item-img">
          <div class="history-item-content">
            <div class="history-item-tit"> {{item.title}} </div>
            <p class="history-item-val" v-for="(j,m) in item.val" :key="m"> {{j}} </p>
          </div>
        </div>
      </div>
    </div>
    <div class="concept-type">
      <div class="concept-chs-title" data-aos="fade-up" data-aos-duration="1000">冻眠食品概念分类</div>
      <div class="concept-en-title" data-aos="fade-up" data-aos-duration="1000">Concept and classification of frozen food</div>
      <div class="classification">
        <div class="classification-1" data-aos="fade-up-right" data-aos-duration="1500">
          <img :src="require('@/assets/freeze/class1.png')" width="200" height="200">
          <span class="title">冷却食品</span>
          <div class="value">
            1、不需要冻结，是将食品的温度降到接近冻结点，并在此温度下保藏的食品。
          </div>
        </div>
        <div class="classification-2" data-aos="fade-up-left" data-aos-duration="1500">
          <img :src="require('@/assets/freeze/class2.png')" width="200" height="200">
          <span class="title">冻结食品</span>
          <div class="value">
            1、是冻结后在低于冻结点的温度保藏的食品。
          </div>
          <div class="value">
            2、冷却食品和冻结食品合称冷冻食品，可按原料及消费形式分为果蔬类、水产类、肉禽蛋类、米面制品、条例方便食品类这五大类。
          </div>
        </div>
      </div>
      <!-- <el-row :gutter="20">
        <el-col :span="12">
          <div class="titles">冷却食品</div>
          <p class="values">1、不需要冻结，是将食品的温度降到接近冻结点，并在此温度下保藏的食品。</p>
        </el-col>
        <el-col :span="12">
          <div class="titles">冻结食品</div>
          <p class="values">1、是冻结后在低于冻结点的温度保藏的食品。</p>
          <p class="values">2、冷却食品和冻结食品合称冷冻食品，可按原料及消费形式分为果蔬类、水产类、肉禽蛋类、米面制品、条例方便食品类这五大类。</p>
        </el-col>
      </el-row> -->
    </div>
    <div class="contrast">
      <div class="contrast-chs-title" data-aos="fade-up" data-aos-duration="1000">冷冻技术对比</div>
      <div class="contrast-en-title" data-aos="fade-up" data-aos-duration="1000">Comparison of refrigeration technology</div>
      <div class="contrast-content">
        <div class="left" data-aos="fade-up-right" data-aos-duration="1000">
          <div class="contrast-content-titles">普通风冷冷冻</div>
          <p class="contrast-content-values">普通风冷冷冻解冻后有血水渗出，这正是食物中的美味成分和营养成分。</p>
        </div>
        <div class="right" data-aos="fade-up-left" data-aos-duration="1000">
          <div class="contrast-content-titles">冻眠冷冻</div>
          <p class="contrast-content-values">激动后不会破坏细胞，没有血水渗出，完美保存食物中的美味和营养成分。</p>
        </div>
      </div>
      <div class="contrast-img" data-aos="zoom-in" data-aos-duration="1000">
        <img :src="require('@/assets/freeze/bg3.png')" width="1000" height="614">
      </div>
      <div class="contrast-air">
        <div class="contrast-air-title" data-aos="fade-up" data-aos-duration="500">空气</div>
        <el-row :gutter="20">
          <el-col :span="8" v-for="(item,i) in air_list" :key="i" data-aos="fade-up" data-aos-duration="1000">
            <div class="contrast-air-item">
              <div class="contrast-air-item-subtitle">{{item.title}}</div>
              <p class="contrast-air-item-val">{{item.value}}</p>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="contrast-liquid">
        <div class="contrast-liquid-title" data-aos="fade-up" data-aos-duration="500">液体</div>
        <el-row :gutter="20">
          <el-col :span="8" v-for="(item,i) in liquid_list" :key="i" data-aos="fade-up" data-aos-duration="1000">
            <div class="contrast-liquid-item">
              <div class="contrast-liquid-item-subtitle">{{item.title}}</div>
              <p class="contrast-liquid-item-val">{{item.value}}</p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="contrast-table">
      <div class="contrast-table-chs-title" data-aos="fade-up" data-aos-duration="500">冷冻技术对比表</div>
      <div class="contrast-table-en-title" data-aos="fade-up" data-aos-duration="500">Comparison of refrigeration technology</div>
      <div class="contrast-table-content">
        <div class="left" data-aos="fade-right" data-aos-duration="1000">
          <div class="left-titles">实验方法对比</div>
          <img :src="require('@/assets/freeze/table1.png')" width="689" height="461">
        </div>
        <div class="right" data-aos="fade-left" data-aos-duration="1000">
          <div class="right-titles">实验参数对比</div>
          <img :src="require('@/assets/freeze/table2.png')" width="689" height="461">
        </div>
      </div>
    </div>
    <el-backtop :visibility-height="300" :bottom="100" />
  </div>
</template>

<script>
import Nav from '@/components/nav'
export default {
  data () {
    return {
      history_list: [
        { image: require('@/assets/freeze/history1.png'), title: '1、冰块冷冻时代', val: ['冰块冷冻技术是一种古老的食品保存技术。早在战国时期(公元前475年-公元前221年)，距今约2500年前，中国人已懂得利用冰冷冻储藏食品。皇室贵族用一种叫“冰鉴”的盛酒器来储藏酒，民间用冰窖。', '古代希腊人和罗马人则会在地窖中放入压碎的雪块用以食品的冷冻。生活在寒冷的北极圈的爱斯基摩人，在冻土下挖一个地窖储存食物，过两三年后仍可食用。'] },
        { image: require('@/assets/freeze/history2.png'), title: '2、冷冻装置的发明', val: ['20世纪初，美国人克拉伦斯·伯德耶(Clarence Birdseye)受爱斯基摩人捕鱼后储存起来的启发，发明了快速冷冻装置。于1928年创立了他的第一家冷冻食物公司。20世纪30年代，家用冰箱开始在美国普及，冷冻技术为上世纪的大众带来了福音。'] },
        { image: require('@/assets/freeze/history3.png'), title: '3、大型冷库与速冻食品', val: ['速冻食品起源于美国，起初生产发展十分缓慢，直到二次世界大战后，速冻食品才迅速发展起来。1953年美国系统地研究了速冻食品，提出了著名的TTT概念，并制定了《冷冻食品制造法规》。从此以后，速冻食品实现工业化生产并进入超级市场，大型冷库技术风靡了全世界，速冻食品也广泛被人们接受了。', '现在世界上使用的快速冷冻装置、大型冷库大多是根据美国人伯德耶的思路制造的，这种快速冷冻技术持续了一百多年，至今仍风行于世。'] },
        { image: require('@/assets/freeze/history4.png'), title: '4、冻眠速冻技术时代', val: ['冻眠技术(TOMIN)在20世纪90年代由日本的山田·义夫社长发明。冻眠技术能使食物保质时间比普通冷冻下延长10倍以上，大大改写了TTT理论中关于时间对冷冻食品品质影响的常规数据。在同样的冷冻效果下，冻眠技术比液氮冷冻的成本降低了70%以上。'] },
      ],
      air_list: [
        { title: '热传导慢', value: '形成冷冻白霉点，因冻结时间长，形成大的冰晶破坏细胞。' },
        { title: '产生血水', value: '从食物里流出大量凝缩了食物美味成分的血水。' },
        { title: '品质低下', value: '味道、口感、外观、食品出成率来看，明显品质低下。' },
      ],
      liquid_list: [
        { title: '热传导快', value: '用热导率快的液体作为媒介，冻结速度提高20倍！不破坏细胞。' },
        { title: '极少的血水', value: '缩水保鲜，维持食品的生鲜水嫩状态。' },
        { title: '品质提升', value: '味道、口感、外观、食品出成率，全部保持难以置信的效果。' },
        { title: '节省空间', value: '用热导率快的液体作为媒介，冻结速度提高20倍！不破坏细胞。' },
        { title: '改善作业环境', value: '不需在冷库内作业，减轻作业人员负担' },
        { title: '操作方法简单', value: '味道、口感、外观、食品出成率，全部保持难以置信的效果。' },
        { title: '多品种、多业种', value: '食用肉类、水产、加工品、面类、熟食都能广泛使用。' },
        { title: '可长期保存 ', value: '强力完全冰冷后可长期保存，也利于在库管理。' },
        { title: '任选解冻方法 ', value: '常温、低温、加热、流动水都可，不需要特别的解冻柜。' },
      ],
    }
  },
  mounted () {
    window.scrollTo(0, 0);
  },
  components: {
    Nav,
  }
}
</script>

<style lang="less" scoped>
.freeze-concept {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 1600px;
  min-height: 2000px;
  .banner {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 750px;
    margin: 0 auto;
    .banner-bg {
      width: 1920px;
      height: 750px;
      background: url("../assets/freeze-concept.png") no-repeat;
      background-size: cover;
      background-position: 50%;
    }
  }
  .principle {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 1400px;
    margin: 50px auto;
    & .en-title {
      font-size: 48px;
      font-weight: bold;
      color: #e6e6e6;
      letter-spacing: 5px;
      text-align: center;
    }
    & .chs-title {
      font-size: 36px;
      font-weight: bold;
      color: #000;
      letter-spacing: 5px;
      text-align: center;
      margin-top: -30px;
    }
    & .p-text {
      font-size: 22px;
      font-family: Microsoft YaHei;
      line-height: 36px;
      color: #0a0c0d;
      letter-spacing: 3px;
      margin: 60px auto;
      text-indent: 50px;
    }
    .principle-img {
      text-align: center;
      margin-bottom: 50px;
    }
  }
  .historys {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 1600px;
    background-color: #f7f7f7;
    padding: 50px 0;
    box-shadow: inset 0px 3px 16px rgba(0, 0, 0, 0.15);
    .history-row {
      width: 1400px;
      margin: 0 auto;
      .history-chs-title {
        font-size: 36px;
        font-weight: bold;
        color: #000;
        margin-bottom: 10px;
        text-align: center;
      }
      .history-en-title {
        font-size: 20px;
        color: #6c6d6e;
        text-transform: lowercase;
        text-align: center;
        margin-bottom: 60px;
      }
      .history-item {
        display: flex;
        justify-content: space-between;
        width: 1400px;
        padding: 30px;
        background-color: #fff;
        box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        margin-bottom: 40px;
        .history-item-img {
          width: 300px;
          height: 200px;
          margin-right: 30px;
          border-radius: 5px;
        }
        .history-item-content {
          & .history-item-tit {
            font-size: 30px;
            font-family: Microsoft YaHei;
            line-height: 36px;
            color: #00a0e9;
            letter-spacing: 3;
          }
          & .history-item-val {
            font-size: 20px;
            font-family: Microsoft YaHei;
            line-height: 36px;
            color: rgba(10, 12, 13, 0.8);
            letter-spacing: 3;
            text-indent: 45px;
            margin-top: 5px;
          }
        }
      }
    }
  }
  .concept-type {
    width: 1400px;
    margin: 80px auto 0;
    .concept-chs-title {
      font-size: 36px;
      font-weight: bold;
      color: #000;
      margin-bottom: 10px;
      text-align: center;
    }
    .concept-en-title {
      font-size: 20px;
      color: #6c6d6e;
      text-transform: lowercase;
      text-align: center;
      margin-bottom: 60px;
    }
    .classification {
      display: flex;
      justify-content: space-around;
      &-1,
      &-2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 484px;
        height: 609px;
        background-color: #f8f8f8;
        border-radius: 10px;
        padding: 40px;
        img {
          -webkit-user-drag: none;
        }
        .title {
          position: relative;
          font-size: 24px;
          font-weight: bold;
          color: #333;
          padding: 40px 0 20px;
          letter-spacing: 4px;
          margin-bottom: 30px;
          &::after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            width: 30px;
            height: 3px;
            background-color: #333;
          }
        }
        .value {
          font-size: 22px;
          color: #000;
          line-height: 36px;
          letter-spacing: 3px;
        }
      }
    }
    // & .titles {
    //   width: 220px;
    //   background: #00a0e9;
    //   border-radius: 45px;
    //   color: #fff;
    //   font-size: 24px;
    //   font-weight: bold;
    //   text-align: center;
    //   padding: 20px 54px;
    //   margin-bottom: 34px;
    //   letter-spacing: 3px;
    // }
    // & .values {
    //   width: 614px;
    //   font-size: 22px;
    //   font-family: Microsoft YaHei;
    //   line-height: 36px;
    //   color: #0a0c0d;
    //   letter-spacing: 3px;
    // }
  }
  .contrast {
    width: 1400px;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
    padding: 60px 0;
    margin: 80px auto;
    .contrast-chs-title {
      font-size: 36px;
      font-weight: bold;
      color: #000;
      margin-bottom: 10px;
      text-align: center;
    }
    .contrast-en-title {
      font-size: 20px;
      color: #6c6d6e;
      text-transform: lowercase;
      text-align: center;
      margin-bottom: 60px;
      font-weight: bold;
    }
    .contrast-content {
      display: flex;
      justify-content: space-around;
      width: 1000px;
      margin: 0 auto 50px;
      &-titles {
        width: 425px;
        color: #00a0e9;
        font-size: 22px;
        font-weight: bold;
        text-align: center;
        letter-spacing: 3px;
        margin-bottom: 5px;
      }
      &-values {
        width: 425px;
        font-size: 22px;
        line-height: 36px;
        color: #0a0c0d;
        letter-spacing: 3px;
        text-align: center;
      }
    }
    .contrast-img {
      width: 1000px;
      height: 614px;
      margin-bottom: 50px;
      margin: 0 auto;
      & img {
        border-radius: 10px;
      }
    }
    .contrast-air,
    .contrast-liquid {
      position: relative;
      width: 1000px;
      margin: 60px auto 0;
      .contrast-air-title,
      .contrast-liquid-title {
        position: relative;
        height: 47px;
        font-size: 36px;
        font-weight: bold;
        color: #0a0c0d;
        letter-spacing: 3px;
        padding-left: 17px;
        margin-bottom: 30px;
      }
      .contrast-air-title {
        border-left: 5px solid #00a0e9;
      }
      .contrast-liquid-title {
        border-left: 5px solid #f3b713;
      }
      .contrast-air-item,
      .contrast-liquid-item {
        width: 320px;
        height: 197px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.08);
        padding: 30px;
        margin-bottom: 20px;
        .contrast-air-item-subtitle,
        .contrast-liquid-item-subtitle,
        .contrast-air-item-val,
        .contrast-liquid-item-val {
          font-size: 22px;
          line-height: 36px;
          color: rgba(10, 12, 13, 0.8);
        }
        .contrast-air-item-subtitle {
          color: #00a0e9;
        }
        .contrast-liquid-item-subtitle {
          color: #f3b713;
        }
      }
    }
  }
  .contrast-table {
    width: 1400px;
    margin: 0 auto 50px;
    .contrast-table-chs-title {
      font-size: 36px;
      font-weight: bold;
      color: #000;
      margin-bottom: 10px;
      text-align: center;
    }
    .contrast-table-en-title {
      font-size: 20px;
      color: #6c6d6e;
      text-transform: lowercase;
      text-align: center;
      margin-bottom: 60px;
      font-weight: bold;
    }
    .contrast-table-content {
      display: flex;
      justify-content: space-between;
      .left,
      .right {
        &-titles {
          width: 689px;
          color: #00a0e9;
          font-size: 24px;
          font-weight: bold;
          text-align: center;
          letter-spacing: 3px;
          margin-bottom: 30px;
        }
        & img {
          object-fit: cover;
        }
      }
    }
  }
}
</style>