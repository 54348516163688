import { render, staticRenderFns } from "./FreezeConcept.vue?vue&type=template&id=0d7a3722&scoped=true&"
import script from "./FreezeConcept.vue?vue&type=script&lang=js&"
export * from "./FreezeConcept.vue?vue&type=script&lang=js&"
import style0 from "./FreezeConcept.vue?vue&type=style&index=0&id=0d7a3722&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d7a3722",
  null
  
)

export default component.exports